<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  ">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >
                    
                    <label> امر الشراء</label>
                    <v-select
                      v-model="purchase_order_id"
                      :options="optionPurchesOrder"
                      :reduce="(val) => val.value"
                      @input="getDetails(purchase_order_id)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
   <b-col md="6" xl="4">
              <b-form-group label-for="birth_date">
                <label> التاريخ </label>
                 <flat-pickr v-model="date"    class="form-control"
                      placeholder="حدد تاريخ " />
                <!-- <b-form-input v-model="person.dateOfBirth" placeholder="" /> -->
              </b-form-group>
            </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> المورد</label>
                    <v-select
                      v-model="vendor_id"
                      label="name"
                      :options="optionVendor"
                      :reduce="(val) => val.id"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="file"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>   الملف</label>
                    <b-form-file
                      v-model="file"
                      accept=".pdf"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> ملاحظة</label>
                    <b-form-input
                      id="input-default"
                      v-model="notes"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الحسم</label>
                    <b-form-input
                      id="input-default"
                      v-model="discount"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="(item,index) in details"
                cols="12"
              >
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-none d-lg-flex">

                  <b-row class="flex-grow-1 px-1">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="2"
                      lg="2"
                    >
                      <label>
                        التفاصيل
                      </label>
                    </b-col>

                  </b-row>
                  <div class="form-item-action-col" />
                </div>

                <!-- Form Input Fields OR content inside bordered area  -->
                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <!-- Single Item Form Headers -->
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>المادة</label>
                          <v-select
                            v-model="item.item_id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="ItemData"
                            label="name"

                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="2"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>الكمية</label>
                          <b-form-input
                            v-model="item.quantity"
                            type="number"
                            class="mb-2"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>الواحدة</label>
                          <v-select
                            v-model="item.unit_id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="ubitsOption"
                            label="en_name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />

                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>العملة</label>
                          <v-select
                            v-model="item.currency_id"
                            :dir="
                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            :options="optionCurrency"
                             label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label>السعر</label>
                          <b-form-input
                            v-model="item.price"
                            type="text"
                            placeholder="السعر "
                            class="mb-2"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="3"
                      xl="3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-for="employe"
                          :state="errors.length > 0 ? false : null"
                        >

                          <label> المورد</label>
                          <v-select
                            v-model="item.vendor_id"
                            label="name"
                            :options="optionVendor"
                            :reduce="(val) => val.id"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <!-- <b-col
                      cols="12"
                      lg="3"
                    >
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            label="  biller"
                            label-for="payment-method"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              v-model="item.bill_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="billerOption"
                              label="name"
                              :clearable="false"
                              :reduce="(val) => val.id"
                              class="mb-2 item-selector-title"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </b-col> -->
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label=" ملاحظة"
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="item.notes"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                    <b-col
                      cols="1"
                      lg="1"
                    >
                      <validation-provider
                        #default="{ errors }"
                      >
                        <b-form-group
                          label-for="notes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class=""
                          >الاجمالي</label>
                          {{ item.total=item.quantity * item.price }}
                          <!-- <b-form-input
                                  v-model="item.total"
                                  type="text"
                                  placeholder="الاجمالي "
                                  class="mb-2"
                                /> -->
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                  </b-row>
                  <div
                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                  >
                    <feather-icon
                      v-if="index != 0"
                      size="16"
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col
                cols="6"
                lg="6"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  <span>
                    اضافة مادة اخرى
                  </span>
                </b-button>
              </b-col>
              <b-col
                cols="4"
                lg="4"
              >

                <label
                  class=""
                >الاجمالي الكلي</label>
                {{ totalAmount }}

              </b-col>

            </b-row>

            <b-col
              md="1"
              xl="3"
            >
              <b-button
                variant="purple"
                @click="add"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle"> إضافة فاتورة  </span>
              </b-button>
            </b-col>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" ">
      <!-- search input -->
     

      <!-- table -->
     <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ totalFamilies }}      عدد الفواتير</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refFamiliesListTable"
        class="position-relative"
        :items="fetchPrice"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(file)="data"
        >
      <span >

 <a target="_blank"
:href="data.item.file"
 >
 <feather-icon icon="LinkIcon" />
 عرض الملف
 </a>
 </span>
        </template>
        <template

#cell(actions)="data"
>
<b-dropdown
  variant="link"
  no-caret
  :right="$store.state.appConfig.isRTL"
>
  <template #button-content>
    <feather-icon
      icon="MoreVerticalIcon"
      size="16"
      class="align-middle text-body"
    />
  </template>
  <b-dropdown-item-button

        @click="
          $router.push({
            name: 'show-purchase-bill',
            params: { id: data.item.id },
          })
        "
      >
        <feather-icon icon="EditIcon" class="mr-50" />
        <span>عرض</span>
      </b-dropdown-item-button>
<b-dropdown-item-button
v-if="data.item.purchase_order_id.status != '3'"
        @click="
          $router.push({
            name: 'edit-purchase-bill',
            params: { id: data.item.id },
          })
        "
      >
        <feather-icon icon="EyeIcon" class="mr-50" />
        <span>تعديل</span>
      </b-dropdown-item-button>
      
      <b-dropdown-item-button v-if="data.item.purchase_order_id.status != '3'" @click="deletePacts(data.item.id)">
        <feather-icon icon="TrashIcon" class="mr-50" />
        <span>حذف</span>
      </b-dropdown-item-button>
</b-dropdown>
</template>
         
            </b-table>

          

         
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFamilies"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Ripple from 'vue-ripple-directive'
import { required, max, is_earlier } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BPagination } from 'bootstrap-vue';
import usePriceoffersList from "./list";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  VBToggle,
 
BCard,
 BTable,
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    flatPickr,
    ToastificationContent,
    required,
    BDropdown,
    BPagination,
BCard,
 BTable,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup(){
      const {
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchPrice,
      

      // Extra Filters
    } = usePriceoffersList()
    return {
      search: null,
      fetchPrice,

      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },
  data() {
    return {
      file: '',
      body: '',
      vendor_id: '',
      optionVendor: [],
      itemFormBlankItem: {
        item_id: '',
        quantity: 1,
        total: 0,
        unit_id: '',
        price: 0,
        currency_id: 1,
        vendor_id: '',
        notes: '',

      },
      details: [
        {
          item_id: '',
          quantity: 1,
          total: 0,
          unit_id: '',
          price: 0,
          currency_id: 1,
          vendor_id: '',
          notes: '',

        },
      ],
      ubitsOption: [],
      ItemData: [],
      optionPurchesOrder: [],
      purchase_order_id: '',

      columns: [
        {
          label: 'امر الشراء ',
          field: 'purchase_order_id.code',
        },
        {
          label: 'المانح ',
          field: 'vendor_id.name',
        },
        {
          label: ' الملف ',
          field: 'file',
        },

      ],
      rows: [],
      date: '',
      billerOption: [],
      notes: '',
      employe: '',
      discount: '',
      id: '',
      searchTerm: '',
      optionerewardType: [
      ],
      item: '',
      optionitem: [],
      optionCurrency: [],
      allPurchesOrder: [],
    }
  },
  computed: {
    totalAmount() {
      let sum = 0
      for (let i = 0; i < this.details.length; i++) {
        sum += this.details[i].quantity * this.details[i].price
      }
      return sum
    },
  },

  created() {
    this.getPacts()
    this.getUnit()
    this.getVendor()
    this.getoptionCurrency()
    this.getPurchesOrder()
    this.getItem()
    this.getBiller()
  },

  methods: {
    getDetails(id) {
  

      this.details = this.allPurchesOrder.find(el => el.id == id).details
    },
    getBiller() {
      this.$http.get('/api/v1/donor-biller').then(res => {
        // console.log('rewards', response.data.data)
        this.billerOption = res.data.data
      })
    },
    getoptionCurrency() {
      this.optionCurrency = []
      this.$http.get('/api/v1/currency').then(res => {
        // console.log(res);

        res.data.forEach(el => {
          this.optionCurrency.push({ id: el.id, name: `${el.name} ` })
        })
        // console.log(this.optionCurrency);
      })
    },
    getPurchesOrder() {
      this.optionPurchesOrder = []
      this.$http.get('/api/v1/purchase-order').then(res => {
        // console.log(res);
        this.allPurchesOrder = res.data.data
        res.data.data.forEach(el => {
          this.optionPurchesOrder.push({ value: el.id, label: `${el.code}  ` })
        })
        // console.log(this.optionemploye);
      })
    },
    getVendor() {
      this.optionVendor = []
      this.$http.get('/api/v1/vendor').then(res => {
        // console.log('itemres',res);
        this.optionVendor = res.data.data

        // console.log(this.optionVendor);
      })
    },
    getItem() {
      this.ItemData = []
      this.$http.get('/api/v1/item').then(res => {
        // console.log('itemres',res);
        this.ItemData = res.data.data

        // console.log(this.ItemData);
      })
    },
    getUnit() {
      this.ubitsOption = []
      this.$http.get('/api/v1/unit').then(res => {
        // console.log('unit',res);
        this.ubitsOption = res.data

        // console.log(this.ubitsOption);
      })
    },

    deletePacts(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/purchase-bill/${id}`
          this.$http.delete(url).then(res => {
            // console.log(res);
            this.refetchData()
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },
    add() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const formData = new FormData()
              formData.append('purchase_order_id', this.purchase_order_id)
              formData.append('vendor_id', this.vendor_id)
 formData.append('date', this.date)
              formData.append('notes', this.notes)
              formData.append('file', this.file)
              formData.append('total', this.totalAmount)
              formData.append('discount', this.discount)
              formData.append('details', JSON.stringify(this.details))

              // console.log("formData",formData)
              this.$http.post('/api/v1/purchase-bill', formData).then(res => {
                // console.log(res);
                this.purchase_order_id = ''
                this.vendor_id = ''
                this.notes = ''
                this.file = ''
                this.totalAmount = ''
                this.discount = ''
                this.details = [{
                  item_id: '',
                  quantity: 1,
                  total: 0,
                  unit_id: '',
                  price: 0,
                  currency_id: 1,
                  vendor_id: '',
                  notes: '',
                  is_approved: 1,
                }]

                this.refetchData()
                this.$swal({
                  title: '',
                  text: 'تمت أضافة  بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    addNewItemInItemForm() {
      this.details.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )
    },
    removeItem(index) {
      this.details.splice(index, 1)
    },
    getPacts() {
      this.$http.get('/api/v1/purchase-bill').then(res => {
        // console.log('pact', res.data.data)
        this.rows = res.data.data
      })
    },

    //
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
button.btn.btn-primary.btn-sm {
    margin: 17px;
}
.but {
  top: 40px;
}
</style>
